var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "linkedin-candidate-view visible" }, [
    _c(
      "div",
      [
        _c("linkedin-search-area", {
          ref: "linkedinSearchArea",
          attrs: { "page-view-id": _vm.pageViewId },
          on: { "action-dosearch": _vm.handleDoSearch },
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "candidate-list",
          },
          [
            _vm.candidateList.length == 0 && !_vm.loading
              ? _c("div", { staticClass: "candidate-list-empty" }, [
                  _c("span", { staticClass: "empty-img" }),
                  _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
                ])
              : _vm._e(),
            _vm.candidateList.length > 0
              ? _vm._l(_vm.candidateList, function (candidate) {
                  return _c("candidate-item", {
                    key: candidate.tbdResumeId,
                    attrs: { item: candidate },
                  })
                })
              : _vm._e(),
          ],
          2
        ),
        _vm.candidateList.length > 0
          ? _c(
              "el-pagination",
              {
                staticClass: "candidate-list-pagination",
                attrs: {
                  "current-page": _vm.pager.current,
                  "page-sizes": [20, 30, 50],
                  "page-size": _vm.pager.size,
                  layout: "prev, pager, next, slot, total, sizes",
                  total: _vm.pager.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              },
              [
                _c("span", { staticClass: "pagination-text" }, [
                  _c(
                    "span",
                    [
                      _vm._v(
                        "\n                    前往\n                    "
                      ),
                      _c("el-input", {
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handlePagerJump(_vm.pagerJump)
                          },
                        },
                        model: {
                          value: _vm.pagerJump,
                          callback: function ($$v) {
                            _vm.pagerJump = _vm._n($$v)
                          },
                          expression: "pagerJump",
                        },
                      }),
                      _vm._v("\n                    页\n                "),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handlePagerJump(_vm.pagerJump)
                        },
                      },
                    },
                    [_vm._v("\n                    跳转\n                ")]
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }