var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "linkedin-search-vue" },
    [
      _c(
        "div",
        { staticClass: "search-area" },
        [
          _c(
            "el-form",
            {
              ref: "personalCandidateSearchForm",
              staticClass: "linkedin-search-form",
              attrs: {
                size: "mini",
                model: _vm.personalCandidateSearch,
                rules: _vm.personalCandidateSearchRules,
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "location-item", attrs: { prop: "location" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "search-city-btn",
                      attrs: { title: _vm.selectedCity.text },
                    },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom-start",
                            width: "629",
                            "visible-arrow": _vm.provinceCityConfig.arrowIsShow,
                            trigger: "click",
                          },
                          model: {
                            value: _vm.provinceCityConfig.provinceCityIsShow,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.provinceCityConfig,
                                "provinceCityIsShow",
                                $$v
                              )
                            },
                            expression: "provinceCityConfig.provinceCityIsShow",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "search-city-content",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c("i", [_vm._v(_vm._s(_vm.selectedCity.text))]),
                              _c("span", { staticClass: "el-icon-arrow-down" }),
                            ]
                          ),
                          _c("province-city", {
                            ref: "provinceCity",
                            attrs: {
                              "grid-length": 7,
                              enableHeader: _vm.provinceCityConfig.enableHeader,
                              enableFooter: _vm.provinceCityConfig.enableFooter,
                            },
                            on: {
                              confirm: _vm.handleCitySelectConfirm,
                              cancel: _vm.handleCitySelectCancel,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-input", {
                    staticClass: "search-input search-input-key",
                    attrs: {
                      maxlength: 128,
                      placeholder: "多个关键词使用空格隔开",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.personalCandidateSearch.keywords,
                      callback: function ($$v) {
                        _vm.$set(_vm.personalCandidateSearch, "keywords", $$v)
                      },
                      expression: "personalCandidateSearch.keywords",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "title" } },
                [
                  _c("span", { staticClass: "form-item-label" }, [
                    _vm._v("职位"),
                  ]),
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: { maxlength: 128 },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.personalCandidateSearch.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.personalCandidateSearch, "title", $$v)
                      },
                      expression: "personalCandidateSearch.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "companyName" } },
                [
                  _c("span", { staticClass: "form-item-label" }, [
                    _vm._v("公司"),
                  ]),
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: { maxlength: 128 },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.personalCandidateSearch.companyName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.personalCandidateSearch,
                          "companyName",
                          $$v
                        )
                      },
                      expression: "personalCandidateSearch.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "realName" } },
                [
                  _c("span", { staticClass: "form-item-label" }, [
                    _vm._v("姓名"),
                  ]),
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: { maxlength: 128 },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.personalCandidateSearch.realName,
                      callback: function ($$v) {
                        _vm.$set(_vm.personalCandidateSearch, "realName", $$v)
                      },
                      expression: "personalCandidateSearch.realName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "form-select-item", attrs: { prop: "degree" } },
                [
                  _c("span", { staticClass: "form-item-label" }, [
                    _vm._v("学历"),
                  ]),
                  _c(
                    "el-select",
                    {
                      staticClass: "personal-el-select",
                      attrs: {
                        placeholder: "请选择学历",
                        "popper-class": "personal-el-dropmenu",
                      },
                      on: { change: _vm.handleDegree },
                      model: {
                        value: _vm.personalCandidateSearch.degree,
                        callback: function ($$v) {
                          _vm.$set(_vm.personalCandidateSearch, "degree", $$v)
                        },
                        expression: "personalCandidateSearch.degree",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: "1", label: "高中及以下" },
                      }),
                      _c("el-option", { attrs: { value: "2", label: "大专" } }),
                      _c("el-option", { attrs: { value: "3", label: "本科" } }),
                      _c("el-option", { attrs: { value: "4", label: "硕士" } }),
                      _c("el-option", { attrs: { value: "5", label: "MBA" } }),
                      _c("el-option", { attrs: { value: "6", label: "博士" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isShowMoreSearch
                ? [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-select-item",
                        attrs: { prop: "yearofexperience" },
                      },
                      [
                        _c("span", { staticClass: "form-item-label" }, [
                          _vm._v("工作年限"),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "personal-el-select",
                            attrs: {
                              placeholder: "请选择工作年限",
                              "popper-class": "personal-el-dropmenu",
                            },
                            on: { change: _vm.handleYear },
                            model: {
                              value:
                                _vm.personalCandidateSearch.yearofexperience,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.personalCandidateSearch,
                                  "yearofexperience",
                                  $$v
                                )
                              },
                              expression:
                                "personalCandidateSearch.yearofexperience",
                            },
                          },
                          _vm._l(
                            _vm.yearOfExperiencesRangeGroupedItems,
                            function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.text, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "more-btn text-operate-btn",
                  on: { click: _vm.showMoreSearch },
                },
                [
                  _vm.isShowMoreSearch
                    ? [
                        _vm._v(
                          "\n                    收起更多\n                    "
                        ),
                        _c("i", {
                          staticClass: "icon-font icon-toggle-up-double",
                        }),
                      ]
                    : [
                        _vm._v(
                          "\n                    展开更多\n                    "
                        ),
                        _c("i", {
                          staticClass: "icon-font icon-toggle-down-double",
                        }),
                      ],
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("search-summary", {
        ref: "searchSummary",
        on: {
          "search-summary-remove": _vm.handleRemoteSummary,
          "clear-search": _vm.clearSearch,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }