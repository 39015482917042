var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "linkedin-candidate-store" }, [
    _c("div", [
      _c("div", { staticClass: "linkedin-base-info" }, [
        _c("a", {
          staticClass: "candidate-name",
          attrs: {
            href: `/#/linkedinCandidateDetail/${_vm.item.tbdResumeId}`,
            target: "_blank",
          },
          domProps: { textContent: _vm._s(_vm.item.realName) },
        }),
        _c("span", {
          staticClass: "candidate-experience",
          domProps: { textContent: _vm._s(_vm.item.yearOfExperienceInfo) },
        }),
        _c("span", {
          staticClass: "candidate-location",
          domProps: { textContent: _vm._s(_vm.item.locationInfo) },
        }),
      ]),
      _c("div", { staticClass: "candidate-row clearfix" }, [
        _c("ul", { staticClass: "candidate-info" }, [
          _c("li", [
            _c("i", {
              staticClass: "fa fa-black-tie",
              attrs: { title: "职位" },
            }),
            _c("p", {
              domProps: { textContent: _vm._s(_vm.item.presentEmployerInfo) },
            }),
          ]),
          _vm.item.degreeInfo
            ? _c("li", [
                _c("i", {
                  staticClass: "fa fa-trophy",
                  attrs: { title: "学历" },
                }),
                _c("p", {
                  domProps: { textContent: _vm._s(_vm.item.degreeInfo) },
                }),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }