<template>
    <div class="linkedin-search-vue">
        <div class="search-area">
            <el-form
                size="mini"
                class="linkedin-search-form"
                :model="personalCandidateSearch"
                ref="personalCandidateSearchForm"
                :rules="personalCandidateSearchRules"
            >
                <el-form-item prop="location" class="location-item">
                    <div class="search-city-btn" :title="selectedCity.text">
                        <el-popover
                            placement="bottom-start"
                            width="629"
                            :visible-arrow="provinceCityConfig.arrowIsShow"
                            v-model="provinceCityConfig.provinceCityIsShow"
                            trigger="click"
                        >
                            <span slot="reference" class="search-city-content">
                                <i>{{ selectedCity.text }}</i>
                                <span class="el-icon-arrow-down"></span>
                            </span>

                            <province-city ref="provinceCity" :grid-length="7"
                                           :enableHeader="provinceCityConfig.enableHeader"
                                           :enableFooter="provinceCityConfig.enableFooter"
                                           @confirm="handleCitySelectConfirm"
                                           @cancel="handleCitySelectCancel"></province-city>
                        </el-popover>
                    </div>
                    <el-input
                        class="search-input search-input-key"
                        :maxlength="128"
                        v-model="personalCandidateSearch.keywords"
                        placeholder="多个关键词使用空格隔开"
                        @keyup.enter.native="search"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="title">
                    <span class="form-item-label">职位</span>
                    <el-input
                        class="search-input"
                        :maxlength="128"
                        v-model="personalCandidateSearch.title"
                        @keyup.enter.native="search"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="companyName">
                    <span class="form-item-label">公司</span>
                    <el-input
                        class="search-input"
                        :maxlength="128"
                        v-model="personalCandidateSearch.companyName"
                        @keyup.enter.native="search"
                    ></el-input>
                </el-form-item>
                <!-- <p class="guess-line">猜你想搜：</p> -->
                <el-form-item prop="realName">
                    <span class="form-item-label">姓名</span>
                    <el-input
                        class="search-input"
                        :maxlength="128"
                        v-model="personalCandidateSearch.realName"
                        @keyup.enter.native="search"
                    ></el-input>
                </el-form-item>
                <el-form-item class="form-select-item" prop="degree">
                    <span class="form-item-label">学历</span>
                    <el-select v-model="personalCandidateSearch.degree" placeholder="请选择学历" @change="handleDegree" class="personal-el-select" popper-class="personal-el-dropmenu">
                        <el-option value="1" label="高中及以下"></el-option>
                        <el-option value="2" label="大专"></el-option>
                        <el-option value="3" label="本科"></el-option>
                        <el-option value="4" label="硕士"></el-option>
                        <el-option value="5" label="MBA"></el-option>
                        <el-option value="6" label="博士"></el-option>
                    </el-select>
                </el-form-item>
                <template v-if="isShowMoreSearch">
                    <el-form-item class="form-select-item" prop="yearofexperience">
                        <span class="form-item-label">工作年限</span>
                        <el-select v-model="personalCandidateSearch.yearofexperience" placeholder="请选择工作年限" @change="handleYear" class="personal-el-select" popper-class="personal-el-dropmenu">
                            <el-option
                                v-for="item in yearOfExperiencesRangeGroupedItems"
                                :key="item.value"
                                :label="item.text"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>
                <span class="more-btn text-operate-btn" @click="showMoreSearch">
                    <template v-if="isShowMoreSearch">
                        收起更多
                        <i class="icon-font icon-toggle-up-double"></i>
                    </template>
                    <template v-else>
                        展开更多
                        <i class="icon-font icon-toggle-down-double"></i>
                    </template>
                </span>
            </el-form>
        </div>
        <search-summary ref="searchSummary" @search-summary-remove="handleRemoteSummary" @clear-search="clearSearch"></search-summary>
    </div>
</template>
<script>
import yearOfExperiencesRangeGroupedItemsData from '#/js/config/yearOfExperiencesRangeGroupedItems.json';
import candidateSearchMap from '#/js/config/candidateSearchMap.json';
import { internationalMobile as mobileTest, email as emailTest, telephone as telephoneTest } from '#/js/util/validators.js';

import ProvinceCity from '#/component/common/province-city.vue';
import searchSummary from '../personal-candidates/search-summary.vue';

const DEFAULT_DATERANGE_FORMAT = "YYYY-MM-DD";

function formatData(items) {
    items.forEach(item => {
        item.text = item.text || item.Text || item.name;
        item.value = item.value || item.Value;
    })
    return items;
}

const yearOfExperiencesData = formatData(JSON.parse(JSON.stringify(yearOfExperiencesRangeGroupedItemsData)));
const DEFAULT_AREA = '全国';
const DEFAULT_AREA_ITEM = {
    id: 0,
    text: DEFAULT_AREA
};

const SUMMARY_PRRAM = {
    companyName: '',
    title: '',
    keywords: '',
    location: '',
    realName: '',
    degree: '',
    yearofexperience:'',
}

export default {
    components:{
        ProvinceCity,
        searchSummary,
    },
    props:{
        pageViewId: String,
        isAllSelected: Boolean,
        importLoading: Boolean
    },
    data(){
        return{
            provinceCityConfig: {
                enableHeader: true,
                enableFooter: true,
                provinceCityIsShow: false,
                arrowIsShow: false,
            },
            selectedCity: {
                text: '全国',
                id: '',
                isSelect: true,
            },
            isShowMoreSearch: false,
            params:{},
            yearOfExperiencesRangeGroupedItems:yearOfExperiencesData,
            degreeText:'',
            yearText:'',
            isbyHotSearch:false,
            personalCandidateSearch: {
                companyName: '',
                title: '',
                keywords: '',
                location: '',
                realName: '',
                degree: '',
                yearofexperience:''
            },
            personalCandidateSearchRules: {
            },
        }
    },
    computed:{
    },
    mounted(){
        if (localStorage.getItem('myCompanyCandidatesParams')) {
            Object.assign(this.params, JSON.parse(localStorage.getItem('myCompanyCandidatesParams')));

            localStorage.removeItem('myCompanyCandidatesParams');
        }
        

        this.areaModal = this.$refs.areaModal;
        this.area = this.$refs.area;
        this.summary = this.$refs.searchSummary;
    },
    methods: {
        clearSearch() {
            this._clearAllFilters();

            this.search();
        },
        
        handlehotSearch(){
            this.isbyHotSearch = false;
        },
        getSearchParamName(key,value){
            return candidateSearchMap[key] || '';
        },

        showMoreSearch() {
            this.isShowMoreSearch = !this.isShowMoreSearch;
        },

        makeUpSummary(param) {
            let _self = this,arr = [],description='';
            Object.keys(param).forEach(key =>{
                let value = param[key];
                if((key in SUMMARY_PRRAM && value)||(key=='yearofexperience' && value==0)) {
                    switch (key) {
                        case 'location':
                            value = this.selectedCity.text;
                            break;
                        case 'keywords':
                        case 'title':
                        case 'companyName':
                        case 'realName':
                            value = value;
                            break;
                        case 'degree':
                            value = _self.degreeText;
                            break;
                        case 'yearofexperience':
                            value = _self.yearText;
                            break;
                    }

                    description = _self.getSearchParamName(key,value);

                    if(value){
                        arr.push({
                            id: key,
                            text: value,
                            description:description
                        });
                    }
                }
            })
            this.summary.items=arr;
        },

        extractParams() {
            this.params.title = this.personalCandidateSearch.title;
            this.params.companyName = this.personalCandidateSearch.companyName;
            this.params.realName = this.personalCandidateSearch.realName;
            this.params.keywords = this.personalCandidateSearch.keywords;
            this.params.degree = this.personalCandidateSearch.degree;
            this.params.isFreshSearch = true;
        },

        validateParam() {
            let valid = true;
            Object.keys(this.params).forEach(key => {
                let value = this.params[key];
                if(value && value.trim) {
                    value = value.trim();
                }
                if(_isDangerousString(value)){
                    valid = false;
                    shortTips('您输入的内容包含非法字符，请重新输入');
                    return false;
                }
            })
            return valid;
        },
        search() {
            this.extractParams();
            if(this.validateParam()){
                this.$emit('action-dosearch',{params:this.params,isbyHotSearch:this.isbyHotSearch});
                this.makeUpSummary(this.params);
            }
        },

        onRemoteSummaryItem(item) {
            switch (item.id) {
                case "location":
                    this._removeLocation();
                    break;
                case "companyName":
                    this._removeCompanyName();
                    break;
                case "title":
                    this._removeTitle();
                    break;
                case "keywords":
                    this._removeKeywords();
                    break;
                case "realName":
                    this._removeRealName();
                    break;
                case "yearofexperience":
                    this._removeYearOfExperience();
                    break;
                case "degree":
                    this._removeDegree();
                    break;
                default:
                    break;
            }

            this.search();
        },

        _clearAllFilters(){
            this._removeLocation();
            this._removeCompanyName();
            this._removeTitle();
            this._removeKeywords();
            this._removeRealName();
            this._removeYearOfExperience();
            this._removeDegree();
        },

        _removeDegree() {
            this.params.degree = '';
            this.degreeText = '';
            this.personalCandidateSearch.degree = '';
        },

        _removeCompanyName() {
            this.personalCandidateSearch.companyName='';
        },

        _removeTitle() {
            this.personalCandidateSearch.title='';
            this.isbyHotSearch = false;
        },

        _removeKeywords() {
            this.params.keywords = '';
            this.personalCandidateSearch.keywords='';
        },

        _removeLocation() {
            this.$refs.provinceCity.clearSelected(true);
            this.selectedCity = {
                text: '全国',
                id: '',
                isSelect: true,
            };
            this.params.locationText = '';
            this.params.location  = '';
            this.personalCandidateSearch.location = '';
        },

        _removeRealName() {
            this.personalCandidateSearch.realName='';
        },

        _removeYearOfExperience(){
            this.params.minyearofexperience = null
            this.params.maxyearofexperience = null;
            this.params.yearofexperience = null;
            this.yearText = '';
            this.personalCandidateSearch.yearofexperience = '';
        },

        _onBatchImportClick(){
            this.$emit('event-batchImport-click')
        },
        
        handleCitySelectConfirm() {
            if (this.$refs.provinceCity.selectedCity[0]) {
                this.selectedCity = this.$refs.provinceCity.selectedCity[0];
                this.params.location  = this.selectedCity.id;
                this.params.locationText = this.selectedCity.text;
            } else {
                this.selectedCity = {
                    text: '全国',
                    id: '',
                    isSelect: true,
                };
                this.params.locationText = '';
                this.params.location  = '';
            }
            this.search();

            this.$refs.provinceCity.currentIndex = undefined;
            this.$refs.provinceCity.selectedProvince = '';
            return this.provinceCityConfig.provinceCityIsShow = false;
        },
        handleCitySelectCancel() {
            if (this.$refs.provinceCity.selectedCity[0] && this.$refs.provinceCity.selectedCity[0].id !== this.selectedCity.id) {
                this.$refs.provinceCity.selectCity(this.selectedCity);
            }
            this.$refs.provinceCity.currentIndex = undefined;
            this.$refs.provinceCity.selectedProvince = '';
            return this.provinceCityConfig.provinceCityIsShow = false;
        },
        
        handleDegree(val){
            this.params.degree = this.degree;
            switch(val){
                case null: 
                    this.degreeText = '';
                    break;
                case '1':
                    this.degreeText = '高中及以下';
                    break;
                case '2':
                    this.degreeText = '大专';
                    break;
                case '3':
                    this.degreeText = '本科';
                    break;
                case '4':
                    this.degreeText = '硕士';
                    break;
                case '5':
                    this.degreeText = 'MBA';
                    break;
                case '6':
                    this.degreeText = '博士';
                    break;
            }
            this.search();
        },
        handleYear(val){
            switch(val){
                case null: 
                this.params.minyearofexperience = null;
                this.params.maxyearofexperience = null;
                this.params.yearofexperience = null;
                this.yearText = '';
                break;
                case '0': 
                this.params.minyearofexperience = null;
                this.params.maxyearofexperience = 3;
                this.params.yearofexperience = 0;
                this.yearText = '3年以内';
                break;
                case '3': 
                this.params.minyearofexperience = 3;
                this.params.maxyearofexperience = 5;
                this.params.yearofexperience = 3;
                this.yearText = '3至5年';
                break;
                case '5': 
                this.params.minyearofexperience = 5;
                this.params.maxyearofexperience = 10;
                this.params.yearofexperience = 5;
                this.yearText = '5至10年';
                break;
                case '10': 
                this.params.minyearofexperience = 10;
                this.params.maxyearofexperience = null;
                this.params.yearofexperience = 10;
                this.yearText = '10年以上';
                break;
            }
            this.search();
        },
        handleHotjob(text){
            this.title = text;
            this.params.title = text;
            this.isbyHotSearch = true;
            this.search();
        },
        handleRemoteSummary(item){
            this.onRemoteSummaryItem(item);
        },
        handleCheckALL(val){
            console.log(val);
            this.$emit('check-all',val);
        }
    },
};

</script>

<style lang="scss">
.search-area{
    display: block;
    position: relative;
    // padding: 24px 12px 0;
    padding: 20px;
    // background-color: #f8f8f8;
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
}

.search-wrap .search-icon .icon-font{
    font-size: 20px;
}

.search-wrap {
    padding: 10px 0;
}

.more-params{
    .check-all-box{
        margin-left: 12px;
        position: absolute;
        top: 14px;
        .trigger-label{
            color: #bbb;
            font-size: 14px;
        }
    }
    .import-candidate-btn{
        background: #38bc9c;
        border-radius: 2px;
        height: 36px;
        width: 130px;
        float: right;
        text-align: center;
        color: #fff;
        line-height: 36px;
        user-select: none;
        &:hover{
            cursor: pointer;
        }
    }

    .import-candidate-gray {
        float: right;
        margin-right: 20px;

        .btn-text {
            display: inline-block;
            width: 196px;
            height: 36px;
            line-height: 36px;
            background: #FDAA65;
            border-radius: 2px;
            cursor: pointer;
            text-align: center;
            color: #fff;
            margin-left: 5px;

            &:hover {
                background-color: #E38736;;
            }
            
            &-gray {
                background-color: #D3D3D3;

                &:hover {
                    background-color: #D3D3D3;
                    cursor: not-allowed;
                }
            }
        }

        .icon-font {
            &:hover {
                color: #FDAA65;
            }
        }
    }
}

.more-params .dropmenu .dropmenu-text {
    color: #666;
}
.param-item-tip .icon-font {
    transform: scale(0.5);
    color: $primary;
}

.param-item-tip>span {
    cursor: pointer;
    margin-top: -10px;
}

.linkedin-search-vue{

    // .linkedin-search-form {
    //     position: relative;
    //     display: flex;
    //     flex-wrap: wrap;
    //     justify-content: space-between;

    //     .form-select-item.el-form-item {
    //         .search-input.el-date-editor {
    //             .el-input__icon {
    //                 display: none;
    //             }
    //         }
    //         .el-input__inner {
    //             width: 397px;
    //         }
    //     }

    //     .guess-line {
    //         display: block;
    //         width: 100%;
    //     }

    //     .search-city-content {
    //         display: flex;
    //         align-items: center;

    //         i {
    //             display: inline-block;
    //             max-width: 50px;
    //             overflow: hidden;
    //             text-overflow: ellipsis;
    //             white-space: nowrap;
    //         }
    //     }
    // }

    .linkedin-search-form {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 50px;

        .guess-line {
            display: block;
            width: 100%;
        }

        .search-city-btn > span {
            display: inline-block;
        }

        .search-city-content {
            display: flex;
            align-items: center;
            padding-right: 16px;

            i {
                display: inline-block;
                padding: 0 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .el-form-item--mini.el-form-item {
            width: 23%;
            display: inline-block;
            margin-bottom: 16px;
            &:first-child {
                width: 100%;
            }
            .el-form-item__content .el-input__inner,
            .el-form-item__label {
                height: 36px;
                line-height: 36px;
            }
            .el-form-item__content {
                position: relative;
                width: 100% !important;
                .form-item-label {
                    height: 34px;
                    width: 68px;
                    text-align: center;
                    display: inline-block;
                    position: absolute;
                    z-index: 1;
                    top: 1px;
                    left: 1px;
                    white-space: nowrap;
                    background: #fff;
                    line-height: 34px;
                    font-size: 14px;
                    color: #333;
                    border-radius: 4px;
                    &::after {
                        content: "";
                        display: inline-block;
                        width: 1px;
                        height: 18px;
                        background: #ddd;
                        position: absolute;
                        right: 0;
                        top: 8px;
                    }
                    &.form-item-select {
                        .el-input__inner {
                            padding: 0 20px;
                            border: none;
                            height: 34px;
                            line-height: 34px;
                            font-size: 14px;
                            color: #333;
                            background-color: transparent;
                        }
                    }
                    &.search-select-key {
                        width: 120px;
                        &::after {
                            display: none;
                        }
                    }
                    &.search-select-companyname {
                        width: 106px;
                    }
                    .el-input__icon {
                        width: 20px;
                        line-height: 34px;
                        color: #333;
                    }
                }
            }
            .el-select {
                width: 100%;
            }
            .el-input__inner {
                padding-left: 78px;
                font-size: 14px;
                color: #666;
                &::placeholder {
                    color: #999;
                    font-size: 14px;
                }
            }

            &.location-item {
                
                .el-form-item__content {
                    display: flex;
                    align-items: center;
                    width: 815px;
                    height: 36px;
                    line-height: 36px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    overflow: hidden;
                }

                .search-input-key {
                    width: 100%;

                    .el-input__inner {
                        padding-left: 0;
                        height: 34px;
                        line-height: 34px;
                        border: 0 none;
                    }
                }
            }
        }
        .form-select-item.el-form-item {
            .search-input.el-date-editor {
                .el-input__icon {
                    display: none;
                }
            }
            .el-input__inner {
                // width: 397px;
                width: 100%;
            }
        }
        .el-age-editor.el-date-editor.el-input__inner:hover {
            border-color: #ddd;

            &.is-active:hover {
                border-color: $primary;
            }
        }
        .search-input-label.el-form-item {
            // width: 397px;
            width: 100%;
            margin-bottom: 10px;
            &.job-hunting-will {
                width: 815px;
            }
            .el-form-item__label {
                color: #333;
                margin: 0;
            }
            .button {
                height: 36px;
                line-height: 36px;
            }
            .el-form-item__content {
                line-height: 36px;
            }
        }
        .customer-select-input,
        .search-input.el-input,
        .search-input.el-autocomplete {
            // width: 397px;
            width: 100%;
            color: #666;
            &.search-input-key {
                // width: 815px;
                width: 100%;
                .el-input__inner {
                    padding: 0;
                    padding-left: 78px;
                }
            }
            &.company-name {
                .el-input__inner {
                    padding: 0;
                    padding-left: 116px;
                }
            }
        }
        .search-input.el-button-group {
            .el-button.el-button--default {
                padding: 0;
                width: 76px;
                text-align: center;
                font-size: 14px;
                background: #fff;
                color: #666;
                border: 1px solid #ddd;
                &:nth-of-type(1) {
                    border-top-left-radius: 14px;
                    border-bottom-left-radius: 14px;
                }
                &:nth-last-of-type(1) {
                    border-top-right-radius: 14px;
                    border-bottom-right-radius: 14px;
                }
                &.selected {
                    background: $primary;
                    color: #fff;
                    border-color: $primary;
                }
            }
        }
        .radio-list.el-radio-group {
            .el-radio {
                margin-right: 20px;
                margin-bottom: 0;
                line-height: 36px;
                .el-radio__label {
                    padding-left: 6px;
                }
            }
            .el-radio.is-checked {
                .el-radio__label {
                    color: #666;
                }
            }
        }
        .more-btn {
            position: absolute;
            bottom: 20px;
            // right: 20px;
            left: 0;
            .icon-font {
                font-size: 14px;
                color: $primary;
                cursor: pointer;
            }
            &:hover, &:focus {
                .icon-font {
                    color: #48CAAC;
                    text-decoration: underline;
                }
            }
            &:active {
                .icon-font {
                    color: #36B495;
                    text-decoration: underline;
                }
            }
        }
        .search-tip {
            width: 100%;
            padding: 25px 0;
            border-top: 1px solid #ddd;
            display: flex;
            .tip-title {
                width: 90px;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: $primary;
                .icon-font {
                    font-size: 28px;
                    margin-bottom: 6px;
                    color: $primary;
                }
            }
            .tip-content {
                margin-bottom: 0;
                margin-right: 10px;
            }
            .icon-font.icon-quxiao {
                font-size: 20px;
                color: #979797;
                cursor: pointer;
                &:hover {
                    color: red;
                }
            }
        }
    }


    .more-btn {
        position: absolute;
        bottom: 20px;
        right: 20px;
        .icon-font {
            font-size: 14px;
            color: $primary;
            cursor: pointer;
        }
        &:hover, &:focus {
            .icon-font {
                color: #48CAAC;
                text-decoration: underline;
            }
        }
        &:active {
            .icon-font {
                color: #36B495;
                text-decoration: underline;
            }
        }
    }
}
.personal-el-dropmenu{
    .el-scrollbar{
        ul{
            padding: 0;
            li.el-select-dropdown__item{
                padding: 4px 20px;
                box-sizing: content-box;
                font-size: 15px;
            }
        }
    }
}
</style>


