<template>
    <div class="linkedin-candidate-store">
        <div>
            <div class="linkedin-base-info">
                <a class="candidate-name" v-text="item.realName" :href="`/#/linkedinCandidateDetail/${item.tbdResumeId}`" target="_blank"></a>
                <span class="candidate-experience" v-text="item.yearOfExperienceInfo"></span>
                <span class="candidate-location" v-text="item.locationInfo"></span>
            </div>
            <div class="candidate-row clearfix">
                <ul class="candidate-info">
                    <li>
                        <i class="fa fa-black-tie" title="职位"></i>
                        <p v-text="item.presentEmployerInfo"></p>
                    </li>
                    <li v-if="item.degreeInfo">
                        <i class="fa fa-trophy" title="学历"></i>
                        <p v-text="item.degreeInfo"></p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    
export default {
    components:{
    },
    mounted(){
    },
    props:{
        pageViewId: String,
        item: Object
    },
    data(){
        return{
        }
    },
    methods: {
    }
};

</script>
<style lang="scss">
%ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.linkedin-candidate-store {
    padding: 25px 7px;
    border-top: none;
    border-bottom: 1px solid #f6f6f6;
    position: relative;

    &:hover {
        background: #f6f6f6;
        .icon-shoucangjia,.icon-kafei,.icon-tuijian{
            display: block;
        }
        
        .operate-btn {
            display: block;
        }
    }

    .order-pager {
        .volume-setting {
            display: inline-block;
            float: right;
            position: absolute;
            right: 230px;
            border: 1px solid #cccccc;
            padding: 5px 18px;
            border-radius: 4px;
            .dropmenu.expand .dropmenu-hd{
                border: none;
            }
            .dropmenu.expand .dropmenu-bd{
                margin-top: 8px;
                margin-left: -19px;
            }
        }
    }

    .linkedin-base-info{
        .candidate-name {
            font-size: 16px;
            font-weight: bold;
            max-width: 150px;
            display: inline-block;
            vertical-align: middle;
            line-height: 32px;
            margin-top: -5px;
            @extend %ellipsis;
        }
    
        .candidate-experience{
            border-right: 1px solid #ccc;
            padding: 0 5px;
        }
    
        .candidate-location{
            padding: 0 5px;
        }
    
        .labels-block{
            display: inline-block;
            margin: 0 15px;
            .label-item{
                background: #ebf8f5;
                padding: 0 8px;
                font-size: 12px;
                color: #199b7e;
                max-width: 100px;
                vertical-align: middle;
                display: inline-block;
                @extend %ellipsis;
            }
        }

        .resume-integrity{
            background: url('//hstatic.hirede.com/lbd/images/icon/score_icon.png') no-repeat center;
            color: #fff;
            padding: 4px 8px;
            font-size: 12px;
            background-size: 100% auto;
            width: 42px;
            display: inline-block;
        }

        .has-viewd{
            font-size: 12px;
            color: #b9b9b9;
            margin-left: 15px;
        }

        .icon-kafei,.icon-shoucangjia,.icon-tuijian{
            display: none;
            font-size: 20px;
            margin-top: -10px;
            &:hover{
                cursor: pointer;
            }
        }

        .icon-kafei,.icon-tuijian{
            margin-right: 15px;
        }

        .candidate-collection.active{
            position: relative;
            .icon-shoucangjia{
                display: block;
                color: #38bc9c;
            }
        }
    }
}

.candidate-info {
    width: 45%;
    float: left;
    margin-bottom: 0;

    .fa {
        float: left;
        line-height: inherit;
        width: 20px;
        margin-right: 0;
    }

    li {
        p {
            @extend %ellipsis;
            max-width: 85%;
            margin-bottom: 0;
        }
    }

    .location-info {
        color: #b9b9b9;
    }
}

/*自定义dark-popover*/
.dark-popover{
    background: #4d4d4d;
    color: #fff;
    border-radius: 4px;
    min-width: 60px;
    text-align: center;
    &.bs-tether-element-attached-bottom .popover-arrow:after{
        border-top-color: #4d4d4d;
    }
    .popover-content{
        color: #fff;
    }
}
</style>
