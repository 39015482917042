<template>
    <div class="linkedin-candidate-view visible">
        <!-- <div class="col-17"> -->
        <div>
            <linkedin-search-area ref="linkedinSearchArea" @action-dosearch="handleDoSearch" :page-view-id="pageViewId"></linkedin-search-area>
            <div class="candidate-list" v-loading="loading">
                <div class="candidate-list-empty" v-if="candidateList.length == 0 && !loading">
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
                <!-- <personal-candidate-store ref="personalCandidateStore" @list-param-start="handlePagerStart" @event-batchImport-click="handleBatchImport" @order-change-take="hanldeTakeChange" @personal-viewDetail="handleViewDetail" @check-item="handleCheckItem" :page-view-id="pageViewId"></personal-candidate-store> -->
                <template v-if="candidateList.length > 0">
                    <candidate-item
                        v-for="candidate in candidateList"
                        :key="candidate.tbdResumeId"
                        :item="candidate"
                    ></candidate-item>
                </template>
            </div>
            <el-pagination
                :current-page="pager.current"
                :page-sizes="[20, 30, 50]"
                :page-size="pager.size"
                class="candidate-list-pagination"
                layout="prev, pager, next, slot, total, sizes"
                :total="pager.total"
                v-if="candidateList.length > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
                <span class="pagination-text">
                    <span>
                        前往
                        <el-input v-model.number="pagerJump" @keyup.enter.native="handlePagerJump(pagerJump)"></el-input>
                        页
                    </span>
                    <span @click="handlePagerJump(pagerJump)">
                        跳转
                    </span>
                </span>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import linkedinSearchArea from './search.vue';
import candidateItem from './candidate-item.vue';
import { candidate as candidateUrl } from '#/js/config/api.json';

export default {
    components:{
        linkedinSearchArea,
        candidateItem,
    },
    data(){
        return{
            loading: false,
            candidateList: [],
            pager: {
                current: 1,
                size: 20,
                total: 0
            },
            pagerJump: 0,
            params:{},
            searchCount:0,
        }
    },
    props:{
        pageViewId:String
    },
    mounted(){
        this.searchArea = this.$refs.linkedinSearchArea;
        this.linkedinCandidateStore = this.$refs.linkedinCandidateStore;
        this.search();
    },
    methods: {
        
        handleDoSearch(val){
            this.search(val.params);
            this.isbyHotSearch = val.isbyHotSearch;
        },

        search(params, silent) {
            if(params){
                Object.assign(this.params, params);
            }
            if(!silent) {
                this.pager.current = 1;
            }
            Object.assign(this.params, {
                start: (this.pager.current - 1) * this.pager.size,
                take: this.pager.size,
                positionQueryType: 3
            });
            if(this.searchCount === 1){
                this.params.sort = 0;
            }
            this.searchCount++;
            this.loading = true;
            _request({
                url: candidateUrl.linkedin_candidate_list,
                method: 'POST',
                data: this.params
            }).then(res => {
                this.candidateList = res.list;
                this.pager.total = res.total;
                this.searchArea.summary.total = res.total;
            }).finally(() => {
                this.loading = false;
            })
            // this.linkedinCandidateStore.loadListData(this.params, this.isFirstLoad).done((rtn) => {
            //     this.searchArea.summary.total=rtn.data.total;
            //     this.isFirstLoad = false;
            // });
        },

        scrollListTop() {
            if(document.querySelector(".search-summary")) {
                document.querySelector('.site-candidates').scrollTop = document.querySelector('.candidate-list').offsetTop - document.querySelector(".search-summary").clientHeight - 20 - 60;
            } else {
                document.querySelector('.site-candidates').scrollTop = document.querySelector('.candidate-list').offsetTop - 60;
            }
        },

        handleSizeChange(val){
            this.pager.size = val;
            this.pager.current = 1;
            this.$nextTick(() => {
                this.search(this.params, true);
                this.scrollListTop();
            })
        },
        handleCurrentChange(current) {
            this.pager.current = parseInt(current) || 1;
            this.scrollListTop();
            this.search(this.params, true);
        },
        handlePagerJump(pagerJump) {
            if(!parseInt(pagerJump)) {
                return false;
            }
            if(pagerJump > Math.ceil(this.pager.total/this.pager.size)) {
                return;
            } else {
                this.handleCurrentChange(pagerJump);
            }
        },
    }
};

</script>

<style lang="scss">

$itemBorder: 1px solid #e1e1e1;

.linkedin-candidate-view{
    display: none;
    &.visible{
        display: block;
    }
    search-summary {
        position: relative;
        margin-bottom: 30px;
        display: block;
    }
    .more-params {
        position: relative;
        margin-top: 30px;
        padding: 6px 0 20px 0;
        border-bottom: 1px solid #d3d3d3;
    }

    .candidate-list {
        position: relative;
        min-height: 100px;
        line-height: 24px;
        // margin-top: 30px;
        margin-top: 20px;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 8px 8px 0 0;

        .el-loading-spinner {
            top: 21px;
        }

        &-empty {
            min-height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                background-size: contain;
                width: 170px;
                height: 160px;
            }
            .empty-data {
                font-size: 14px;
                margin-bottom: 0px;
            }
        }
    }

    .candidate-list-pagination.el-pagination {
        // margin: 40px 0 20px 0;
        // padding: 0;
        padding: 40px 20px 20px;
        font-weight: normal;
        background-color: #ffffff;
        border-radius: 0 0 8px 8px;
        .btn-next, .btn-prev {
            height: 38px;
            border: 1px solid;
        }
        .btn-prev {
            border-right: none;
            border-radius: 4px 0 0 4px;
        }
        .btn-next {
            border-left: none;
            border-radius: 0 4px 4px 0;
        }
        .el-pagination__sizes .el-input{
            width: 87px;
            .el-input__inner {
                color: #666;
                .el-select__caret{
                    color: #999;
                }
            }
        }
        .el-pager {
            border-top: 1px solid;
            border-bottom: 1px solid;
            li {
                min-width: 36px;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
            }
        }
        .btn-next, .btn-prev, .el-pager {
            border-color: #DDDDDD;
        }
        .el-input__inner, .el-pagination__total, .el-pagination__jump {
            height: 38px;
            line-height: 38px;
            font-size: 14px;
        }
        .pagination-text{
            color: #999;
            span{
                height: 38px;
                line-height: 38px;
                font-size: 14px;
                .el-input{
                    width: 48px;
                    margin: 0 5px;
                    &__inner{
                        height: 38px;
                        line-height: 38px;
                        padding: 0 5px;
                    }
                }
                &:nth-of-type(2){
                    margin-left: 5px;
                    color: $primary;
                    cursor: pointer;
                }
            }
        }
        .el-pagination__total,
        .el-pagination__sizes,
        .pagination-text {
            float: right;
        }
    }

    .card {
        float: left;
        width: 290px;
        margin-bottom: 20px;
        h2.title{
            color: #666;
            font-size: 16px;
            position: relative;
            padding-left: 20px;
            margin-bottom: 0;
            display: inline-block;
            &::before{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 8px;
                background-color: #38bc9c;
            }
            &.selected{
                color: #38bc9c;
            }
        }
        .top-bar{
            user-select: none;
            padding: 15px 10px;
            background: #f8f8f8;
            &:hover{
                cursor: pointer;
            }
            .icon-xialajiantouxiangxia{
                font-size: 14px;
                margin-top: 2px;
                transform: rotateX(180deg);
            }
        }
        .my-candidate-board{
            position: relative;
            .icon-riqi{
                font-size: 18px;
                margin-right: 18px;
                &:hover{
                    color: #38bc9c;
                    height: 20px;
                    .my-candidate-popover{
                        display: block;
                    }
                }
            }
            #board-card{
                background:#f8f8f8;
                padding:20px;
                float: left;
                li{
                    display: inline-block;
                    color:#9b9b9b;
                    width: 33%;
                    float: left;
                    margin: 10px 0;
                    text-align: center;

                    .item-title{
                        width:60px;
                        height: 60px;
                        line-height: 60px;
                        border-radius: 100%;
                        font-size: 13px;
                        border:2px solid #dddddd;
                        margin: 0 auto;
                    }
                    .item-number{
                        width: 60px;
                        overflow: hidden;
                        font-weight: bold;
                        text-overflow: ellipsis;
                        margin: 10px auto 0;
                    }
                    &:hover{
                        cursor: pointer;
                        color: #38bc9c;
                        .item-title{
                            border-color: #38bc9c;
                        }
                    }
                    &.selected{
                        color: #38bc9c;
                        .item-title{
                            border-color: #38bc9c;
                        }
                    }
                    &:nth-last-child(1){
                        cursor: not-allowed;
                        color: #9b9b9b;
                        .item-title{
                            border-color: #ddd;
                        }
                    }
                }
            }
            .my-candidate-popover{
                display: none; 
                width: 150px;
                background: #fff;
                border: 1px solid #ddd;
                box-shadow: 5px 5px 5px #ddd;
                position: absolute;
                right: -28px;
                top: 35px;
                z-index: 999;
                font-size: 14px;
                color: #666;
                text-align: center;
                li {
                    line-height: 35px;
                    &:hover {
                        cursor: pointer;
                        background: #f8f8f8;
                    }
                    &.arrow-up {
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        left: 45%;
                        top: -5px;
                        z-index: -1;
                        transform: rotate(45deg);
                        background: #fff;
                        border-top: 1px solid #ddd;
                        border-left: 1px solid #ddd;
                    }
                    &.selected{
                        color: #38bc9c;
                    }
                }
            }
        }
        .my-collections{
            background:#f8f8f8;
            float: left;
            width: 100%;
            padding: 10px;
            font-size: 16px;
            span{
                display: block;
                padding: 10px;
                border-bottom: 1px solid #dddddd;
                &:hover{
                    cursor: pointer;
                    color: #34b092;
                    background: #eee;
                    font-weight: bold;
                }
            }
            &.selected{
                color: #34b092;
            }
        }
        .label-list{
            background:#f8f8f8;
            float: left;
            width: 100%;
            padding: 0 10px 20px;
            a{
                display: inline-block;
                width: 100%;
                line-height: 35px;
                color: #666;
                padding: 0 10px;
                &:hover{
                    color: #34b092;
                    background: #eee;
                    font-weight: bold;
                }
                span{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .label-name{
                    max-width: 180px;
                }
                .label-number{
                    max-width: 50px;
                }
            }
            .list-item.selected{
                a{
                    color: #34b092;
                }
            }
        }
        &.inactive{
            .icon-riqi{
                display: none;
            }
            #board-card{
                display: none;
            }
            ul{
                display: none;
            }
            .icon-xialajiantouxiangxia{
                transform: rotateX(0);
            }
        }
    }
}

.dropmenu-select-arrow-right .order-item radio,
.dropmenu-select-arrow-right .order-item checkbox {
    display: none;
}

.dropmenu-select-arrow-right .order-item {
    border-bottom: $itemBorder;
    padding: 0;
}

.dropmenu-select-arrow-right .order {
    min-height: 0;
}

.dropmenu-select-arrow-right .dropmenu-select-title {
    display: none;
}

.dropmenu-select .dropmenu-select-bd {
    padding: 0;
    border: $itemBorder;
    border-bottom: none;
}

.candidate-view .dropmenu-select-arrow-right .dropmenu-select-bd{
    margin-top: 16px;
}

.candidate-view .candidate-list-wrap {
    z-index: 0;
    position: relative;
}

.dropmenu-select-arrow-right .order-item .dropmenu-item {
    padding: 12px 20px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dropmenu-select-arrow-right .order-item .dropmenu-item.selected:after {
    display: inline-block;
    float: right;
    width: 20px;
    height: 20px;
    font-family: icon-font !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: .2px;
    content: "\e626";
    position: absolute;
    right: 4px;
}

.dropmenu-select-arrow-right .order-item .dropmenu-item.select-all-item {
    display: none;
}

.dropmenu-select .dropmenu-select-bd {
    margin-top: 12px;
    background-color: #fff;
    color: #b9b9b9;
}

.dropmenu-select-arrow-right .order-item .dropmenu-item.selected {
    color: $primary;
}

.dropmenu-select-arrow-right .order-item .dropmenu-item.selected slot {
    color: $primary;
}


.dropmenu-select-arrow-right .order-item .dropmenu-item.selected span{
    color: $primary !important;
}


/*自定义dark-popover*/
.dark-popover{
    background: #4d4d4d;
    color: #fff;
    border-radius: 4px;
    min-width: 60px;
    text-align: center;
    &.bs-tether-element-attached-bottom .popover-arrow:after{
        border-top-color: #4d4d4d;
    }
    .popover-content{
        color: #fff;
    }
}

/*自定义modalTemplete样式*/
.modalTemplete {
    overflow-y: auto;
    .modal-header{
        background: #38BC9C;
        color: #fff;

        .close{
            color: #fff;
        }
    }
    .modal-footer button{
        border-radius: 2px;
    }
}

#importResTemp{
    display: none;
    height: 180px;
    padding-top: 20px;
    .temp-banner{
        padding: 0 40px;
    }
    .temp-main{
        p span{
            color: #38bc9c;
        }
        h2{
            font-weight: bold;
        }
    }
    .theme-color{
        color: #38bc9c;
    }
    .text-grey{
        color: #999!important;
    }
    .text-indent{
        text-indent: 2em;
    }
}
</style>
